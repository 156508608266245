@import url('https://fonts.googleapis.com/css2?family=Estonia&family=Montserrat:ital,wght@0,100;0,300;0,500;1,600;1,800&display=swap');

body {
    font: 400 15px Lato, sans-serif;
    line-height: 1.8;
    color: #818181;
}

.logoTLS {
    width: 100px;
    
}
.page {
    min-height: 600px !important;
    display: block;
   margin: auto;
}
/* *************about1************* */
.header {
    /* background-image: url('../background.png'); */
    width: 100%;
    min-height: auto;
   
}

/* *************about************* */
#myVideo {
width: 100%;
  height: 550px;
  object-fit: cover;
  /* position: fixed; */
  margin-top: 50px;
  z-index: -1;
}

#about {
    display: flex;
    justify-content: center;
    align-items: center; 
    background: rgba(255, 255, 255, 0.5);
    color: #f1f1f1;
    height: 550px;
  }
#about .row .col-sm-6{
     display: flex;
    justify-content: center;
    align-items: center; 
}

#about h4 {
    color: #000;
    padding: 20px;
    text-align: justify;
    font-family: 'Montserrat', cursive;
}

#immobilier h4 ,#digital h4 ,#conseil h4 {
    color: #000;
    padding: 20px;
    text-align: justify;
    font-family: 'Montserrat', cursive;
}

#about .img{
    width: 500px;
    height: 300px;
}
.txtIMMO , .txtDIG{
    text-align: justify;
}
.service{
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnC {
    border: 2px solid #EE7166;
    border-radius: 10px;
    color: #EE7166;
    padding: 10px;
}
.btnC:hover {
    background-color: #EE7166;
    color: #fff;
    text-decoration: none;
}
.dot{
    height: 100px;
    width: 100px;
    color: #000;
    font-size: 3rem;
    background-color: #bbb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

h2 {
    font-size: 30px;
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    margin-bottom: 30px;
}

h4 {
    font-size: 19px;
    line-height: 1.375em;
    color: #303030;
    font-weight: 400;
    margin-bottom: 30px;
}

.jumbotron {
    background-color: #EE7166;
    color: #fff;
    padding: 100px 25px;
    font-family: Montserrat, sans-serif;
}

.container-fluid {
    padding: 60px 50px;
}

.bg-grey {
    background-color: #f6f6f6;
}
/* The animation code */

.icon-service{
    color: #EE7166;
    font-size: 40px;
    
    padding: 20px;
   
}
.logo-small {
    color: #EE7166;
    font-size: 50px;
}

.logo {
    color: #EE7166;
    font-size: 200px;
}

.thumbnail {
    padding: 0 0 15px 0;
    border: none;
    border-radius: 0;
}

.thumbnail img {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}
/* .servicedigital div {
    display: flex;
    justify-content: space-between;    

}
.servicedigital  img {
    width: 120px;
}
 */
 .descriptionService {
     margin: 20px;
 }


.carousel-control.right,
.carousel-control.left {
    background-image: none;
    color: #EE7166;
}
.carousel-indicators {
    bottom: -30px !important;
}
.carousel-indicators li {
    border-color: #EE7166;
}

.carousel-indicators li.active {
    background-color: #EE7166;
}
.item {
    text-align: center;
}

.item h1 {
    
    line-height: 1.375em;
    font-weight: 400;
    font-style: italic;
    margin: 70px 0;
    color: #000;
}
.item a{
    color: #EE7166;
}

.secteur a:hover ,.secteur img:hover{
    text-decoration: none;
    transition: 1s;
    transform: scale(1.1);
}

.item img {
    
    width: 150px;
    height: 150px;
    position: relative;
}
.item a:hover , .item img:hover{
    text-decoration: none;
    transition: 1s;
    transform: scale(1.1);
}
.item-immo img {
    width: 250px;
    height: 250px;
}

.item span {
    font-style: normal;
}

.panel {
    border: 1px solid #EE7166;
    border-radius: 0 !important;
    transition: box-shadow 0.5s;
}
.panel-body{
    text-align: justify;
}

.panel:hover {
    box-shadow: 5px 0px 40px rgba(0, 0, 0, .2);
}

.panel-footer .btn:hover {
    border: 1px solid #EE7166;
    background-color: #fff !important;
    color: #EE7166;
}

.panel-heading {
    color: #fff !important;
    background-color: #EE7166 !important;
    padding: 25px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.panel-footer {
    background-color: white !important;
}

.panel-footer h3 {
    /* font-size: 40px; */
    font-size: 4vh;
    color: #EE7166;
}

.panel-footer h4 {
    color: #aaa;
    font-size: 14px;
}

.panel-footer .btn {
    margin: 15px 0;
    background-color: #EE7166;
    color: #fff;
}

.navbar {
    margin-bottom: 0;
    border: 0 0 2px 2px solid #EE7166;
    background-color: #ffffff;
    z-index: 9999;
    border: 0;
    font-size: 12px !important;
    line-height: 1.42857143 !important;
    letter-spacing: 4px;
    border-radius: 0;
    font-family: Montserrat, sans-serif;
}

.navbar li a,
.navbar .navbar-brand {
    color: #000 !important;
    text-align: center;
}

.navbar-nav li a:hover,
.navbar-nav li.active a {
    color: #fff !important;
    background-color: #EE7166 !important;
}

.navbar-default .navbar-toggle {
    border-color: transparent;
    color: #fff !important;
}

footer .glyphicon {
    font-size: 20px;
    margin-bottom: 20px;
    color: #EE7166;
}

.slideanim {
    visibility: hidden;
}

.slide {
    animation-name: slide;
    -webkit-animation-name: slide;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    visibility: visible;
}
.Toop{
    width: 50px;
    height: 50px; 
    position: sticky;
    top: 85%;
    bottom: 60px;
    left: 95%;
    z-index: 1;
    border-radius: 50%;
    /* background-color: #EE7166; */
    text-align: justify;
    opacity: 0.5;
}
.Toop:hover{
    opacity: 1;
}
.txtServ {
    color: #EE7166;
}

@keyframes slide {
    0% {
        opacity: 0;
        transform: translateY(70%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@-webkit-keyframes slide {
    0% {
        opacity: 0;
        -webkit-transform: translateY(70%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
    }
}

@media screen and (max-width: 768px) {
    #myVideo {
        width: 100%;
          height: auto;
        }
    .col-sm-4 {
        text-align: center;
        margin: 25px ;
    }
    .btn-lg {
        width: 100%;
        margin-bottom: 35px;
    }
    #about .img{
        width: 300px;
        height: 300px;
    }
    .footer-cta  .txt-single-cta {
        text-align: left;
    }
    .Toop{
       display: none;
    }
    #about .col-sm-4{
        display: none;
    }
    .service{
        display: block;
    
    }
    .panel-footer h3 {

    }
}

@media screen and (max-width: 480px) {
    .logo {
        font-size: 150px;
    }
    .txt-single-cta {
        text-align: left;
    }
}
/* footer */
ul {
    margin: 0px;
    padding: 0px;
}
.footer-section {
  background: #151414;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
  padding: 10px;
  text-align: center;
}
.footer-cta  .txt-single-cta {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}
.single-cta i {
  color: #EE7166;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
    max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
      color: #fff;
  line-height: 28px;
}
.footer-social-icon{
   padding: 10px 0;
}
.footer-social-icon a {
    margin: 0 10px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 10px;
}
.divmenu{
    border: 2px solid #EE7166;
    border-radius: 10px;
}


.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #EE7166;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover{
  color: #EE7166;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: #EE7166;
    padding: 13px 20px;
    border: 1px solid #EE7166;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a{
  color: #EE7166;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #EE7166;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}